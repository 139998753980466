import styled from 'styled-components'
import { Wrapper } from '../styles/common'

export const ContentWrapper = styled(Wrapper)`
  padding: 10px;

  @media ${({ theme }) => theme.device.lg} {
    max-width: 1024px;
    padding: 30px;
  }
`
