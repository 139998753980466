import styled, { css } from 'styled-components'
import { StyledSpan, Wrapper } from '../../styles/common'

export const ContentWrapper = styled(Wrapper)`
  @media ${({ theme }) => theme.device.lg} {
    max-width: 1024px;
  }
`

export const ListItem = styled.li`
  margin-bottom: 10px;
`

export const StyledList = styled.ul`
  padding-left: 0;
  margin: -5px 0 0 40px;
`

export const StyledOlList = styled.ol`
  padding-left: 0;
  margin: -5px 0 0 40px;

  &&& li::marker {
    font-size: 1em;

    @media ${({ theme }) => theme.device.sm} {
      font-size: 1.1em;
    }

    @media ${({ theme }) => theme.device.lg} {
      font-size: 1.5em;
    }
  }
`

export const StyledText = styled(StyledSpan)`
  padding: ${props => props.padding || '0 0 15px'};
  font-size: 1.1em;
  line-height: 1.5em;

  &&& {
    a,
    strong {
      font-weight: 900;
    }
  }

  ${props =>
    props.isTitle &&
    css`
      font-size: 1.2em;
      padding-top: 15px;
    `}
`
