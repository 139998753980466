import React from 'react'

// Styles
import { Container } from '../../styles/common'
import {
  StyledList,
  StyledOlList,
  ListItem,
  StyledText,
} from './HtmlRender.styles'

// Utils
import { formatParagraph } from '../../utils/prismicUtils'

const TextElement = ({ item, index }) => {
  return item.type === 'heading5' ? (
    <StyledText
      isTitle
      key={`text-${index}`}
      dangerouslySetInnerHTML={{ __html: formatParagraph(item) }}
    />
  ) : (
    <StyledText
      key={`text-${index}`}
      dangerouslySetInnerHTML={{ __html: formatParagraph(item) }}
    />
  )
}

const OlListElement = ({ list, index }) => {
  return list && list.length > 0 ? (
    <StyledOlList key={index}>
      {list.map((listItem, listIndex) => (
        <ListItem key={listIndex}>
          <StyledText
            dangerouslySetInnerHTML={{
              __html: formatParagraph(listItem),
            }}
          />
        </ListItem>
      ))}
    </StyledOlList>
  ) : (
    <></>
  )
}

const ListElement = ({ list, index }) => {
  return list && list.length > 0 ? (
    <StyledList key={index}>
      {list.map((listItem, listIndex) => (
        <ListItem key={listIndex}>
          <StyledText
            dangerouslySetInnerHTML={{
              __html: formatParagraph(listItem),
            }}
          />
        </ListItem>
      ))}
    </StyledList>
  ) : (
    <></>
  )
}

const formatContent = content => {
  let isListOpened = false
  let olListItems = []
  let ulListItems = []

  return content.map((item, index) => {
    if (item.type === 'o-list-item' || item.type === 'list-item') {
      if (!isListOpened) {
        olListItems = []
        ulListItems = []
      }
      isListOpened = true

      if (item.type === 'o-list-item') {
        olListItems.push(item)
      } else {
        ulListItems.push(item)
      }

      return <></>
    } else {
      if (isListOpened) {
        isListOpened = false

        if (olListItems.length > 0) {
          return (
            <>
              <OlListElement list={olListItems} index={index} />
              <TextElement item={item} index={index} />
            </>
          )
        } else if (ulListItems.length > 0) {
          return (
            <>
              <ListElement list={ulListItems} index={index} />
              <TextElement item={item} index={index} />
            </>
          )
        }
      }

      olListItems = []
      ulListItems = []

      return <TextElement item={item} index={index} />
    }
  })
}

const HtmlRender = ({ content }) => {
  return <Container>{formatContent(content)}</Container>
}

export default HtmlRender
