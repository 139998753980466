import React from 'react'
// Components
import { Seo, PageHeader } from '../components'
import HtmlRender from '../components/HtmlRender/HtmlRender'
// Context
import { usePrismic } from '../context/PrismicContext'
// Styles
import { ContentWrapper } from '../styles/PrivacyPolicyPage.style'

const TermsPage = () => {
  const {
    prismicData: {
      prismicTermsPage: { page_title, terms_content },
    },
  } = usePrismic()

  return (
    <>
      <Seo title={page_title[0].text} />
      <ContentWrapper>
        <PageHeader exitRoute="/">{page_title[0].text}</PageHeader>

        <HtmlRender content={terms_content} />
      </ContentWrapper>
    </>
  )
}

export default TermsPage
